import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import "./ueberuns.styl"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      alloy {
        teams {
          bio
          name
          role
          tag
          titleAfter
          titleBefore
          portrait {
            link
            image {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  const { alloy } = data
  const { teams } = alloy

  return (
    <div id="about" className="main-page">
      <h1 className="page-title">&Uuml;ber uns</h1>
      <p className="description">
        Die Kraft der Heil­kr&auml;u­ter und -pflan­zen vor un­se­rer
        Haus­t&uuml;re bil­det das Herz der Tra­di­tio­nel­len
        Eu­ro­p&auml;i­schen Me­di­zin (TEM). Sie un­ter­st&uuml;t­zen uns
        da­bei, ge­sund zu blei­ben oder ge­sund zu wer­den. Die­se
        Na­tur­heil­kraft in Kom­bi­na­ti­on mit den Er­kennt­nis­sen der
        mo­der­nen Me­di­zin fin­det sich in den Re­zep­tu­ren un­se­rer
        Arz­nei­en wie­der. Ger­ne neh­men wir uns Zeit f&uuml;r ein
        per­s&ouml;n­li­ches Be­ra­tungs­ge­spr&auml;ch. Viel­f&auml;l­ti­ge
        Zu­satz­aus­bil­dun­gen er­m&ouml;g­li­chen es uns, bei Ih­ren
        An­lie­gen in die Tie­fe zu ge­hen. N&uuml;t­zen Sie un­se­re
        Fach­kom­pe­tenz f&uuml;r Ihre Ge­sund­heit. Kom­men Sie ein­fach
        vor­bei. Wir freu­en uns auf Sie.
      </p>
      <br />

      {teams.map((team, index) => (
        <div key={index} className="staff-display">
          <div className="staff-box">
            <div className="staff-box-image">
              {team.portrait && (
                <Img
                  className="staff-image"
                  fluid={team.portrait.image.childImageSharp.fluid}
                />
              )}
            </div>
            <div>
              <b className="content">{team.titleBefore}</b>&nbsp;
              <b className="content">{team.name}</b>&nbsp;
              <b className="content">{team.titleAfter}</b>&nbsp;
              <br />
              <b className="content">{team.role}</b>
              <p className="content">{team.bio}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
